import { admin2 } from './instance'

export const GetBranchStore = async ({
  shopId,
  start,
  limit,
  name,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/branch`,
    params: {
      start,
      limit,
      name,
    },
  })
}
export const GetBranchStoreCount = async ({
  shopId,
  name,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/branch/count`,
    params: {
      name,
    },
  })
}
export const FindBranchStore = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/branch/${id}`,
  })
}

export const BatchCreateBranchStore = async ({
  shopId,
  data,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/branch/batchCreate`,
    data: {
      data,
    },
  })
}

export const CreateBranchStore = async ({
  shopId,
  name,
  address,
  phone,
  order,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/branch`,
    data: {
      name,
      address,
      phone,
      order,
    },
  })
}

export const UpdateBranchStore = async ({
  shopId,
  storeId,
  name,
  address,
  phone,
  order,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/branch/${storeId}`,
    data: {
      name,
      address,
      phone,
      order,
    },
  })
}
